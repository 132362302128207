<template>
    <div class="industry_solutions" id="jumpPoint">        
        <div class="banner">
            <div class="banner_title">
                <p class="p1">行&nbsp;&nbsp;业&nbsp;&nbsp;解&nbsp;&nbsp;决&nbsp;&nbsp;方&nbsp;&nbsp;案</p>
                <p class="p2">超 越 软 件 智 造 未 来</p>
            </div>
        </div>

        <div class="content_area">
            <div class="per_block" v-for="(info,index) in infoes" :key="index" @click="linkChildren(index)">
                <img :src="info.imgUrl" alt="">
                <p class="num">{{info.num}}</p>
                <div class="line"></div>
                <div class="title">{{info.title}}</div>
            </div>
            <span></span>
        </div>
    </div>
</template>

<script>
export default{
    name:'industrySolutions',
    components:{
        
    },
    data(){
        return {
            infoes:[
                {
                    imgUrl:require('./../../assets/industrySolutions/img4.png'),
                    num:"01",
                    title:'汽车零部件行业解决方案',
                },
                {
                    imgUrl:require('./../../assets/industrySolutions/img1.png'),
                    num:"02",
                    title:'压铸行业解决方案',
                },
                {
                    imgUrl:require('./../../assets/industrySolutions/img3.png'),
                    num:"03",
                    title:'塑胶行业解决方案',
                },
                {
                    imgUrl:require('./../../assets/industrySolutions/img2.png'),
                    num:"04",
                    title:'电子电气配套解决方案',
                },
                {
                    imgUrl:require('./../../assets/industrySolutions/img5.png'),
                    num:"05",
                    title:'通信行业配套解决方案',
                },
            ]
        }
    },

    created(){
        this.$nextTick(()=>{
            this.tojumpPoint()
        })
    },

    methods:{
        linkChildren(index){
            if(index == 0){
                this.$router.push({
                    name:'Solution1'
                })
            }else if(index == 1){
                this.$router.push({
                    name:'Solution2'
                })
            }else if(index == 2){
                this.$router.push({
                    name:'Solution3'
                })
            }else if(index == 3){
                this.$router.push({
                    name:'Solution4'
                })
            }else if(index == 4){
                this.$router.push({
                    name:'Solution5'
                })
            }
        },

        tojumpPoint(){
            document.getElementById('jumpPoint').scrollIntoView({
                behavior:'smooth',
                block:'start'
            })
        }
    }
}
</script>

<style scoped lang='scss'>
@import './industrySolutions.scss'
</style>
